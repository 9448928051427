<template>
    <CreationSection :title="$t('common.allergens')" class="wrapper">
        <div class="btn__wrapper">
            <CRButton
                class="btn"
                v-for="allergen in allergens"
                :key="allergen.id"
                :active="!allergen.isHidden"
                @click="updateAllergen(allergen)"
                pattern="toggle"
            >
                <template #icon>
                    <VegetarianSvg />
                </template>
                {{ $filters.getTranslationByKey(allergen.name) }} - {{ allergen.percentage }}%
                <PenSvg v-if="editedAllergen?.id !== allergen.id" @click.stop="selectAllergen(allergen)" class="icon" />
                <CheckSvg
                    v-if="editedAllergen?.id === allergen.id"
                    class="icon"
                    @click.stop="updateAllergenPercentage"
                />
            </CRButton>
        </div>
        <div v-if="editedAllergen">
            <CRInput
                v-model="editedAllergen.percentage"
                class="input"
                :label="$t('placeholder.increaseInPrice')"
                :placeholder="$t('placeholder.increaseInPrice')"
                :help-text="$t('common.addPercentageToIncreaseKitPrice')"
                :errors="errors?.percentage"
                @onChange="$filters.clearError(errors, 'percentage')"
            />
            <CRButton @click="updateAllergenPercentage">
                {{ $t('buttons.update') }}
            </CRButton>
        </div>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import PenSvg from '@/assets/icons/buttons/pen-white.svg?inline';
    import CheckSvg from '@/assets/icons/checks/check-icon.svg?inline';
    import { AllergenApi } from '@/api';
    import { AllergenTypeEnum } from '@/utils/enums';

    export default {
        name: 'AllergensSettings',
        components: { CreationSection, CRButton, VegetarianSvg, CRInput, PenSvg, CheckSvg },
        data() {
            return {
                allergens: [],
                editedAllergen: null,
                errors: {},
            };
        },
        methods: {
            async getAllergens() {
                try {
                    const response = await AllergenApi.getAll({ type: AllergenTypeEnum.ORDER });
                    this.allergens = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            selectAllergen(allergen) {
                this.editedAllergen = { ...allergen };
            },

            async updateAllergen(allergen) {
                try {
                    const response = await AllergenApi.update(allergen.id, { isHidden: !allergen.isHidden });
                    allergen.isHidden = response.data.isHidden;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updateAllergenPercentage() {
                try {
                    const response = await AllergenApi.update(this.editedAllergen.id, {
                        percentage: this.editedAllergen.percentage,
                    });

                    let allergen = this.allergens.find((item) => item.id === response.data.id);
                    if (allergen) {
                        allergen.percentage = response.data.percentage;
                    }

                    this.editedAllergen = null;
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },

        async mounted() {
            setTimeout(async () => await this.getAllergens(), 1000);
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 20px;

            .btn {
                margin-right: 8px;
                margin-bottom: 10px;

                .icon {
                    width: 13px;
                    margin-left: 10px;

                    &:deep() {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }

        .input {
            margin: 30px 0 20px 0;
        }
    }
</style>
