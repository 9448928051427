<template>
    <CreationSection :title="$t('common.deliveryDateSettings')" class="wrapper">
        <p>{{ $t('earliestDeliveryDay.setDeliveryDatesManually') }}</p>
        <CRDatepicker
            inline
            v-model="selectedDays"
            :allowed-dates="allowedDatesRange"
            preventMinMaxNavigation
            :minDate="allowedDatesRange[0]"
            :maxDate="allowedDatesRange[allowedDatesRange.length - 1]"
            model-type="yyyy-MM-dd"
            multiDates
            no-today
            @onDateClick="onDateClick"
        />
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRDatepicker from '@/components/Common/Datepicker/CRDatepicker.vue';
    import { CalendarDaysApi } from '@/api';
    import { difference, throttle } from 'lodash';

    export default {
        name: 'DeliveryDateSettings',
        components: { CreationSection, CRDatepicker },
        data() {
            return {
                calendarDays: [],
                allowedDatesRange: [],
                selectedDays: [],
            };
        },
        methods: {
            onDateClick: throttle(async function (value) {
                if (value.length > this.selectedDays.length) {
                    // remove day
                    const date = difference(value, this.selectedDays)[0];
                    await this.updateCalendarDay(date, false);
                } else {
                    // add day
                    const date = this.selectedDays[this.selectedDays.length - 1];
                    await this.updateCalendarDay(date, true);
                }
            }, 250),

            async updateCalendarDay(date, isDeliveryAllowed) {
                try {
                    const day = this.calendarDays.find((day) => day.date === date);

                    await CalendarDaysApi.update(day.id, { isDeliveryAllowed });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async getCalendarDays() {
                try {
                    const response = await CalendarDaysApi.getAll();

                    this.calendarDays = response.data;
                    this.allowedDatesRange = this.calendarDays.map((calendarDay) => calendarDay.date);
                    const allowedDeliveryDates = this.calendarDays.filter(
                        (calendarDay) => calendarDay.isDeliveryAllowed
                    );
                    this.selectedDays = allowedDeliveryDates.map((calendarDay) => calendarDay.date).reverse();
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getCalendarDays();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        p {
            font-size: $font-14;
            font-style: italic;
            margin-bottom: 20px;
        }
    }
</style>
