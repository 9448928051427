<template>
    <CreationSection :title="$t('earliestDeliveryDay.title')">
        <CRRadio
            class="checkbox"
            v-for="day in earliestDeliveryDays"
            :key="day.id"
            :value="day.id"
            v-model="activeDay"
            @change="radioHandler"
        >
            {{ $t(`earliestDeliveryDay.${day.name}`) }}
        </CRRadio>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import { EarliestDeliveryDayApi } from '@/api';
    import { debounce } from 'lodash';

    export default {
        name: 'EarliestDeliveryDaysSettings',
        components: { CreationSection, CRRadio },
        data() {
            return {
                earliestDeliveryDays: [],
                activeDay: '',
            };
        },
        methods: {
            async getEarliestDeliveryDays() {
                try {
                    const response = await EarliestDeliveryDayApi.getAll();
                    this.earliestDeliveryDays = response.data;

                    this.activeDay = this.earliestDeliveryDays.find((day) => day.isActive).id;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            radioHandler: debounce(async function (val) {
                try {
                    const day = this.earliestDeliveryDays.find((day) => day.id == val);

                    await EarliestDeliveryDayApi.update(day.id, { isActive: true });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1000),
        },

        async mounted() {
            await this.getEarliestDeliveryDays();
        },
    };
</script>

<style lang="scss" scoped>
    .checkbox {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
