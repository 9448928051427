<template>
    <CreationSection :title="$t('common.paymentsOnOff')" class="wrapper">
        <CRToggle
            class="toggler"
            v-for="method in paymentMethods"
            :key="method.id"
            :checked="method.isActive"
            :value="method.isActive"
            @change="handelToggle(method)"
        >
            <span>{{ $t(`paymentMethods.${method.type}`) }}</span>
        </CRToggle>

        <ConfirmationModal
            v-if="isConfirmationModalOpen"
            :title="$t('paymentMethods.disablePaymentMethodsModalTitle')"
            @close="closeConfirmationModal"
            @confirm="updatePaymentMethod"
        />
    </CreationSection>
</template>

<script>
    import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import { PaymentMethodApi } from '@/api';

    export default {
        name: 'PaymentMethod',
        components: { CreationSection, CRToggle, ConfirmationModal },
        data() {
            return {
                paymentMethods: [],
                isConfirmationModalOpen: false,

                editedMethod: null,
            };
        },

        methods: {
            async getPaymentMethods() {
                try {
                    const response = await PaymentMethodApi.getAll();
                    this.paymentMethods = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updatePaymentMethod() {
                try {
                    const response = await PaymentMethodApi.update(this.editedMethod.id, {
                        isActive: !this.editedMethod.isActive,
                    });

                    this.paymentMethods = this.paymentMethods.map((method) => {
                        if (method.id === this.editedMethod.id) {
                            return response.data;
                        }

                        return method;
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.closeConfirmationModal();
                }
            },

            closeConfirmationModal() {
                this.editedMethod = null;
                this.isConfirmationModalOpen = false;
            },

            async handelToggle(paymentMethod) {
                this.editedMethod = paymentMethod;

                if (this.editedMethod.isActive) {
                    this.isConfirmationModalOpen = true;
                } else {
                    await this.updatePaymentMethod();
                }
            },
        },

        async mounted() {
            await this.getPaymentMethods();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .toggler {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media($lg) {
        .wrapper {
            .toggler {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .span {
                font-size: $font-16;
            }
        }
    }
</style>
