<template>
    <div class="checkout-container">
        <div class="checkout-container__inner">
            <h1 class="title">
                {{ $t('navbar.checkoutOrderSettings') }}
            </h1>
            <h2>
                {{ $t('common.generalSettings') }}
            </h2>
            <TimerSettings />
            <PaymentMethodSetting />
            <VATSettings />
            <DeliveryPriceSettings />
            <DeliveryMethodsSetting />
            <h2 class="mt-50">
                {{ $t('common.regularKitsSettings') }}
            </h2>
            <DeliveryDateSettings />
            <DeliveryTimeSettings />
            <EarliestDeliveryDaysSettings />
            <AllergensSettings />
            <AdditionsSettings />
            <RequiredText class="w-full" />
        </div>
    </div>
</template>

<script>
    import TimerSettings from '@/components/Admin/CheckOutSettings/TimerSettings.vue';
    import PaymentMethodSetting from '@/components/Admin/CheckOutSettings/PaymentMethodSetting.vue';
    import VATSettings from '@/components/Admin/CheckOutSettings/VATSettings.vue';
    import DeliveryPriceSettings from '@/components/Admin/CheckOutSettings/DeliveryPriceSettings.vue';
    import DeliveryMethodsSetting from '@/components/Admin/CheckOutSettings/DeliveryMethodsSetting.vue';
    import DeliveryDateSettings from '@/components/Admin/CheckOutSettings/DeliveryDateSettings.vue';
    import DeliveryTimeSettings from '@/components/Admin/CheckOutSettings/DeliveryTimeSettings.vue';
    import EarliestDeliveryDaysSettings from '@/components/Admin/CheckOutSettings/EarliestDeliveryDaysSettings.vue';
    import AllergensSettings from '@/components/Admin/CheckOutSettings/AllergensSettings.vue';
    import AdditionsSettings from '@/components/Admin/CheckOutSettings/AdditionsSettings.vue';
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';

    export default {
        name: 'CheckOutSettings',
        components: {
            TimerSettings,
            PaymentMethodSetting,
            VATSettings,
            DeliveryPriceSettings,
            DeliveryMethodsSetting,
            DeliveryDateSettings,
            DeliveryTimeSettings,
            EarliestDeliveryDaysSettings,
            AllergensSettings,
            AdditionsSettings,
            RequiredText,
        },
    };
</script>

<style lang="scss" scoped>
    .checkout-container {
        padding-bottom: 30px;

        .title {
            display: none;
        }

        h2 {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    @include media($lg) {
        .checkout-container {
            padding-bottom: 60px;

            .checkout-container__inner {
                max-width: 633px;
            }

            .title {
                display: block;
                margin-bottom: 25px;
            }

            h2 {
                margin-top: 0;
                margin-bottom: 30px;
            }

            &:deep(.title__wrapper .title) {
                font-size: $font-20;
            }
        }
    }
</style>
