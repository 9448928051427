<template>
    <CreationSection :title="$t('common.deliveryTimeSettings')" class="wrapper">
        <div class="tag__wrapper" v-if="deliveryTimes.length">
            <CRTag
                class="tag mb-10"
                v-for="deliveryTime in deliveryTimes"
                :key="deliveryTime.id"
                type="additional"
                delete-allowed
                svg-color="#00595A"
                @delete="deleteDeliveryTime(deliveryTime.id)"
            >
                {{ getAdminLabelForDeliveryTime(deliveryTime) }}
            </CRTag>
        </div>
        <CRInput
            class="input"
            v-for="input in deliveryTimesInputsData"
            :key="input.id"
            v-model="deliveryTimeData[input.value]"
            type="time"
            :label="input.label"
            required
            :errors="deliveryTimeDataErrors[input.errors]"
            @onChange="$filters.clearError(deliveryTimeDataErrors, input.errors)"
        />
        <CRCheckbox
            class="input"
            :checked="deliveryTimeData.deliverDayBefore"
            :value="deliveryTimeData.deliverDayBefore"
            v-model="deliveryTimeData.deliverDayBefore"
            @change="deliveryTimeData.deliverDayBefore = !deliveryTimeData.deliverDayBefore"
        >
            {{ $t('otherSettings.deliveryDayBefore') }}
        </CRCheckbox>
        <CRButton @click="addData">
            {{ $t('buttons.addDeliveryTime') }}
        </CRButton>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import { deliveryTimesInputsData } from '@/config/deliveryTimes/deliveryTimes';
    import { validateForm } from '@/helpers/ValidationRulesHelper';
    import { getAdminLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import { DeliveryTimeApi } from '@/api';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox';

    const DELIVERY_TIME_STATE = {
        startTime: '',
        endTime: '',
        deliverDayBefore: false,
    };

    export default {
        name: 'DeliveryTimeSettings',
        components: { CRCheckbox, CreationSection, CRButton, CRInput, CRTag },
        data() {
            return {
                getAdminLabelForDeliveryTime,
                deliveryTimesInputsData,
                deliveryTimes: [],

                deliveryTimeData: { ...DELIVERY_TIME_STATE },
                deliveryTimeDataErrors: {},
            };
        },
        methods: {
            async addData() {
                const validateFormData = validateForm(this.deliveryTimeData, this.deliveryTimeDataErrors);
                this.deliveryTimeDataErrors = validateFormData.errorState;

                if (validateFormData.isValid) {
                    try {
                        const response = await DeliveryTimeApi.store(this.deliveryTimeData);
                        this.deliveryTimes.push(response.data);
                        this.deliveryTimeData = { ...DELIVERY_TIME_STATE };
                    } catch (error) {
                        this.deliveryTimeDataErrors = error.errors;
                    }
                }
            },

            async deleteDeliveryTime(id) {
                try {
                    await DeliveryTimeApi.destroy(id);
                    this.deliveryTimes = this.deliveryTimes.filter((deliveryTime) => deliveryTime.id !== id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async getDeliveryTimes() {
                const response = await DeliveryTimeApi.getAll();
                this.deliveryTimes = response.data;
            },
        },

        async mounted() {
            await this.getDeliveryTimes();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .tag__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 30px;

            .tag {
                margin-right: 8px;
            }
        }

        .input {
            margin: 20px 0;
        }
    }

    @include media($lg) {
        .wrapper {
            .tag__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                margin-bottom: 20px;

                .tag {
                    margin-right: 8px;
                }
            }

            .input {
                margin: 30px 0;
            }
        }
    }
</style>
