<template>
    <CreationSection :title="$t('common.vat')" class="wrapper" v-if="kitVATObj && deliveryVATObj">
        <CRInput
            class="input"
            type="number"
            v-model.number="kitVATObj.percentage"
            :label="$t('placeholder.vatForKits')"
            :placeholder="$t('placeholder.vatForKits')"
            :errors="errors?.kitPercentage"
            @onChange="$filters.clearError(errors, 'kitPercentage')"
            required
        />
        <CRInput
            class="input"
            type="number"
            v-model.number="deliveryVATObj.percentage"
            :label="$t('placeholder.vatForDelivery')"
            :placeholder="$t('placeholder.vatForDelivery')"
            :errors="errors?.deliveryPercentage"
            @onChange="$filters.clearError(errors, 'deliveryPercentage')"
            required
        />
        <CRButton @click="updateVAT">
            {{ $t('buttons.update') }}
        </CRButton>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import { VATApi } from '@/api';
    import { VatEnum } from '@/utils/enums';
    import { TYPE } from 'vue-toastification';
    import { debounce } from 'lodash';

    export default {
        name: 'VATSettings',
        components: { CreationSection, CRButton, CRInput },
        data() {
            return {
                kitVATObj: null,
                deliveryVATObj: null,
                errors: {},
            };
        },
        methods: {
            updateVAT: debounce(async function () {
                await this.makeUpdateRequest('kitVATObj', 'kitPercentage', this.$t('placeholder.vatForKits'));
                await this.makeUpdateRequest(
                    'deliveryVATObj',
                    'deliveryPercentage',
                    this.$t('placeholder.vatForDelivery')
                );
            }, 1000),

            async makeUpdateRequest(state, errorState, message) {
                try {
                    await VATApi.update(this[state].id, { percentage: this[state].percentage });

                    this.$filters.toast(`${message} ${this.$t('otherSettings.updated')}`, { type: TYPE.SUCCESS });
                } catch (error) {
                    this.errors[errorState] = error.errors.percentage;
                }
            },

            async getVAT() {
                try {
                    const response = await VATApi.getAll();

                    this.kitVATObj = response.data.find((vat) => vat.type === VatEnum.KIT);
                    this.deliveryVATObj = response.data.find((vat) => vat.type === VatEnum.DELIVERY);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.getVAT();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .tag__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 30px;

            .tag {
                margin-right: 8px;
            }
        }

        .input {
            margin: 20px 0;
        }
    }

    @include media($lg) {
        .wrapper {
            .tag__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                margin-bottom: 20px;

                .tag {
                    margin-right: 8px;
                }
            }

            .input {
                margin: 30px 0;
            }
        }
    }
</style>
