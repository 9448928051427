import { isEmpty } from 'lodash';
import { translation } from '@/helpers/TranslationHelper';

export const MIN_AGE = 15;
export const MAX_AGE = 80;

export const validateForm = (form, errorState) => {
    let isValid = true;

    Object.entries(form).forEach(([key, val]) => {
        if (key === 'age' && (val < MIN_AGE || val > MAX_AGE)) {
            isValid = false;

            errorState[key] = [translation('errors.minMaxAge', { key, MIN_AGE, MAX_AGE })];
        }

        if (isEmpty(val?.toString())) {
            isValid = false;

            if (errorState) {
                errorState[key] = [translation('errors.fieldIsRequired', { key })];
            }
        }

        if (key === 'uniqueMenus') {
            val.forEach((element) => {
                if (isEmpty(element.dish)) {
                    isValid = false;
                }
            });
        }

        if (key === 'caloriesPeriodsPrices') {
            Object.entries(val).forEach((element) => {
                element[1].forEach((item) => {
                    if (!item.price) {
                        isValid = false;
                    }
                });
            });
        }
    });

    return { isValid, errorState };
};
