import { translation } from '@/helpers/TranslationHelper';

export const deliveryTimesInputsData = [
    {
        id: 1,
        value: 'startTime',
        label: translation('common.deliveryTimeFrom'),
        errors: 'startTime',
    },
    {
        id: 2,
        value: 'endTime',
        label: translation('common.deliveryTimeTo'),
        errors: 'endTime',
    },
];
