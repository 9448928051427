<template>
    <CreationSection :title="$t('common.deliveryMethodsOnOff')" class="wrapper">
        <CRToggle
            class="toggler"
            v-for="method in deliveryMethods"
            :key="method.id"
            :checked="method.isActive"
            :value="method.isActive"
            @change="handelToggle(method.isActive, method.id)"
        >
            <span>{{ $t(`deliveryMethods.${method.type}`) }}</span>
        </CRToggle>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import { DeliveryMethodApi } from '@/api';
    import { throttle } from 'lodash';

    export default {
        name: 'DeliveryMethodsSetting',
        components: { CreationSection, CRToggle },
        data() {
            return {
                deliveryMethods: [],
            };
        },
        methods: {
            async getAllMethods() {
                try {
                    const response = await DeliveryMethodApi.getAll();
                    this.deliveryMethods = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handelToggle: throttle(async function (value, id) {
                try {
                    const response = await DeliveryMethodApi.update(id, { isActive: !value });
                    this.deliveryMethods = this.deliveryMethods.map((method) => {
                        if (method.id === id) {
                            return response.data;
                        }

                        return method;
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1500),
        },
        async mounted() {
            await this.getAllMethods();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .toggler {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media($lg) {
        .wrapper {
            .toggler {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .span {
                font-size: $font-16;
            }
        }
    }
</style>
