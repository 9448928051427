<template>
    <CreationSection :title="$t('common.timerSettings')" class="wrapper" v-if="timer">
        <CRInput
            class="input"
            v-model="timer.startTime"
            :label="$t('placeholder.timerStartTime')"
            :placeholder="$t('placeholder.timerStartTime')"
            type="time"
            required
        />
        <CRInput
            class="input"
            v-model="timer.endTime"
            :label="$t('placeholder.timerEndTime')"
            :placeholder="$t('placeholder.timerEndTime')"
            type="time"
            required
        />
        <CRToggle v-model:checked="timer.isHidden">
            <span>{{ $t('common.disabledHideTimer') }}</span>
        </CRToggle>
        <CRButton class="btn" @click="updateTimer">
            {{ $t('buttons.update') }}
        </CRButton>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'TimerSettings',
        components: { CreationSection, CRInput, CRToggle, CRButton },
        computed: {
            ...mapGetters('timer', ['timer']),
        },
        methods: {
            ...mapActions('timer', ['getTimer', 'updateTimer']),
        },
        async mounted() {
            try {
                await this.getTimer();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .input {
            margin-bottom: 20px;
        }

        .span {
            font-size: $font-14;
        }

        .btn {
            margin-top: 20px;
        }
    }

    @include media($lg) {
        .wrapper {
            .input {
                margin-bottom: 30px;
            }

            .span {
                font-size: $font-16;
            }
        }
    }
</style>
