<template>
    <CreationSection :title="$t('common.additions')">
        <CRCheckbox
            class="checkbox"
            v-for="addition in additions"
            :key="addition.id"
            :checked="!addition.isHidden"
            :value="!addition.isHidden"
            @change="updateAddition(addition)"
        >
            {{ $filters.getTranslationByKey(addition.name) }}
        </CRCheckbox>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import { AdditionApi } from '@/api';
    import { debounce } from 'lodash';

    export default {
        name: 'AdditionsSettings',
        components: { CreationSection, CRCheckbox },
        data() {
            return {
                additions: [],
            };
        },
        methods: {
            async getAdditions() {
                try {
                    const response = await AdditionApi.getAll();
                    this.additions = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            updateAddition: debounce(async function (addition) {
                try {
                    const response = await AdditionApi.update(addition.id, { isHidden: !addition.isHidden });
                    addition.isHidden = response.data.isHidden;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 250),
        },

        async mounted() {
            await this.getAdditions();
        },
    };
</script>

<style lang="scss" scoped>
    .checkbox {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
