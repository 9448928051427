<template>
    <CreationSection :title="$t('common.deliveryPrice')" class="wrapper" v-if="regularPrice && uniquePrice">
        <div class="menu-btn__wrapper">
            <CRButton
                class="btn"
                v-for="button in mealKitButtonsData"
                :key="button.value"
                :active="isUnique === button.value"
                @click="handelSelectPriceType(button.value)"
                pattern="section"
            >
                {{ button.title }}
            </CRButton>
        </div>
        <CRInput
            v-if="!isUnique"
            class="input"
            type="number"
            v-model.number="regularPrice.price"
            :label="$t('common.deliveryPrice')"
            :placeholder="$t('placeholder.enterDeliveryPrice')"
            :errors="errors?.regularPrice"
            @onChange="$filters.clearError(errors, 'regularPrice')"
            required
        />
        <CRInput
            v-else
            class="input"
            type="number"
            v-model.number="uniquePrice.price"
            :label="$t('common.deliveryPrice')"
            :placeholder="$t('placeholder.enterDeliveryPrice')"
            :errors="errors?.uniquePrice"
            required
            @onChange="$filters.clearError(errors, 'uniquePrice')"
        />
        <CRButton @click="updatePrices">
            {{ $t('buttons.update') }}
        </CRButton>
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import { DeliveryPriceApi } from '@/api';
    import { mealKitButtonsData } from '@/config/mealKits/mealKits';
    import { TYPE } from 'vue-toastification';
    import { debounce } from 'lodash';

    export default {
        name: 'DeliveryPriceSettings',
        components: { CreationSection, CRButton, CRInput },
        data() {
            return {
                mealKitButtonsData,
                isUnique: false,

                regularPrice: null,
                uniquePrice: null,
                errors: {},
            };
        },
        methods: {
            handelSelectPriceType(value) {
                this.isUnique = value;
            },

            async updatePrices() {
                if (this.isUnique) {
                    await this.makeUpdateRequest('uniquePrice', this.$t('placeholder.uniqueKitDeliveryPrice'));
                } else {
                    await this.makeUpdateRequest('regularPrice', this.$t('placeholder.regularKitDeliveryPrice'));
                }
            },

            makeUpdateRequest: debounce(async function (state, message) {
                try {
                    await DeliveryPriceApi.update(this[state].id, { price: this[state].price });

                    this.$filters.toast(`${message}${this.$t('otherSettings.updated')}`, { type: TYPE.SUCCESS });
                } catch (error) {
                    this.errors[state] = error.errors?.price;
                }
            }, 1000),

            async getDeliveryPrices() {
                try {
                    const response = await DeliveryPriceApi.getAll();

                    this.regularPrice = response.data.find((price) => !price.isForUnique);
                    this.uniquePrice = response.data.find((price) => price.isForUnique);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.getDeliveryPrices();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .menu-btn__wrapper {
            @include row-align-center;

            margin-top: 15px;

            .btn:first-child {
                margin-right: 8px;
            }
        }

        .tag__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 30px;

            .tag {
                margin-right: 8px;
            }
        }

        .input {
            margin: 20px 0;
        }
    }

    @include media($lg) {
        .wrapper {
            .tag__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                margin-bottom: 20px;

                .tag {
                    margin-right: 8px;
                }
            }

            .input {
                margin: 30px 0;
            }
        }
    }
</style>
